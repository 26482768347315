import React from "react";

const Testimonial = () => {
  return (
    <>
      <section className="home-testimonial-section" style={{overflow:"hidden"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                Happy Clients
              </h2>
              <p className="testimonial-section-info text-center">
                We take this opportunity to introduce ourselves as a highly
                professional security conscious organization, committed to
                achieve excellence for its esteemed clients
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  One of best service for providing security guard and good
                  behaviour with every security. Google Map HR
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                    <span className="author-title">
                      Phoenix Hospital Pvt Ltd.
                    </span>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  Suraskha Security & Allied Services has provided us security
                  guard, housekeeper for our regional office in Mumbai from past
                  3 years and we are satisfied with quality of staff, compliance
                  and overall service.
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                    <span className="author-title">CEO (Noble Hospital)</span>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
