import React from "react";

const HomeBanner1 = () => {
  return (
    <>
      <section style={{ overflow: "hidden" }}>
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner" style={{ overflow: "hidden" }}>
            <div class="carousel-item ">
              <img src="assets/img/img/1.webp" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="assets/img/img/2.webp" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item active">
              <img src="assets/img/img/01.jpeg" class="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </>
  );
};

export default HomeBanner1;
