import React from "react";
import { Link } from "react-router-dom";

const Committed = () => {
  return (
    <>
      <section className="home-goal-section" style={{overflow:"hidden"}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="inner-goal-image">
                <figure className="video-play-image">
                  <img src="assets/img/img/10.jpeg" alt />
                  <div className="overlay02 overlay" />
                  {/* <div className="video-button">
                    <a id="video-container" data-video-id="IUN664s7N-c">
                      <i className="fas fa-play" />
                    </a>
                  </div> */}
                </figure>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="goal-content">
                <div className="pattern-overlay" />
                <div className="title-divider" />
                <h2 className="goal-title">Director Massage !</h2>
                <p className="goal-info">
                  Mr. Amit Pal , has more than 10 years of experience in
                  organizing security of large factories including Multi
                  National companies in areas of operations and administration.
                  He is also specialized in surveillance and security audit.
                </p>
                <Link to="contact" className="button-round-secondary">
                  JOIN US NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="home-progress-section">
        <div className="overlay" />
        <div className="container">
          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Committed;
