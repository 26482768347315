import React from "react";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../pages/WhatsappIcon";

const Footer = () => {
  return (
    <>
      <WhatsappIcon />
      <footer id="colophon" className="site-footer">
        <div className="footer-overlay" />
        <div className="top-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <aside className="widget widget_text img-textwidget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img
                        src="assets/img/img/Suraskha_Security.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="textwidget widget-text">
                    We are pioneers in rendering the services to Commercial
                    Establishment plants, Factories, Multiplexes, Residential
                    Complexes, Construction Sites, and Banks.
                  </div>
                </aside>
                <div className="footer-social-links">
                  <ul>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-facebook-f" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-twitter" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-instagram" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-youtube" aria-hidden="true" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <aside className="widget">
                  <h5 className="widget-title">Quick LInks</h5>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/contact">Carrers</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="col-lg-3 col-md-6">
                <aside className="widget">
                  <h5 className="widget-title">Our Services</h5>
                  <ul>
                    <li>
                      <Link to="/securityguard">Security Guard</Link>
                    </li>
                    <li>
                      <Link to="/eventsecurity">Event Security</Link>
                    </li>
                    <li>
                      <Link to="/hospitalsecurity">Hospital Security</Link>
                    </li>
                    <li>
                      <Link to="/firesafety">Fire Safety</Link>
                    </li>
                    <li>
                      <Link to="femalesecurityguard">
                        Female Security Guard
                      </Link>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="col-lg-3 col-md-6">
                <aside className="widget widget_text school-time">
                  <h5 className="widget-title">Open Hours</h5>
                  <span>
                    <i aria-hidden="true" className="far fa-clock" />
                    09:30 AM - 06:30 PM , Monday - Saturday
                  </span>
                  <Link
                    to="/contact"
                    className="button-round-secondary mt-lg-5 "
                  >
                    JOIN US NOW
                  </Link>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bottom-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="copy-right">
                  <p>
                  2024 © Suraskha Security And Allied Services | Designed by
                    <a className="text-white" href="tel:+918982471422">
                      <b>Web solution & digital marketing </b>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
