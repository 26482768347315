import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import MainLayOut from "./components/MainLayOut";
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Gallery from "./pages/gallery/Gallery";
import SecurityGuard from "./pages/services/SecurityGuard";
import FemaleSecurityGuard from "./pages/services/FemaleSecurityGuard";
import EventSecurity from "./pages/services/EventSecurity";
import OfficeSecurity from "./pages/services/OfficeSecurity";
import BuildingSecurity from "./pages/services/BuildingSecurity";
import BankSecurity from "./pages/services/BankSecurity";
import IndustrialSecurity from "./pages/services/IndustrialSecurity";
import HospitalSecurity from "./pages/services/HospitalSecurity";
import FireSafety from "./pages/services/FireSafety";
import CashManagement from "./pages/services/CashManagement";
import HouseKeepingServices from "./pages/services/HouseKeepingServices";
import LabourConsultants from "./pages/services/LabourConsultants";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<MainLayOut />}>
          <Route index element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/securityguard" element={<SecurityGuard />} />
          <Route
            path="/femalesecurityguard"
            element={<FemaleSecurityGuard />}
          />
          <Route path="/eventsecurity" element={<EventSecurity />} />
          <Route path="/officesecurity" element={<OfficeSecurity />} />
          <Route path="/buildingsecurity" element={<BuildingSecurity />} />
          <Route path="/industrialsecurity" element={<IndustrialSecurity />} />
          <Route path="/banksecurity" element={<BankSecurity />} />
          <Route path="/hospitalsecurity" element={<HospitalSecurity />} />
          <Route path="/firesafety" element={<FireSafety />} />
          {/* <Route path="/cashmanagement" element={<CashManagement />} /> */}
          <Route
            path="/housekeepingservices"
            element={<HouseKeepingServices />}
          />
          <Route path="/labourconsultants" element={<LabourConsultants />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
