import React from 'react'

const EventSecurity = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title"> Event Security</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="title-divider-center mt-lg-5" />
          <h2 className="about-title text-center  ">
            Looking for Quick Short Term Security Services for an event in
            Mumbai?
          </h2>
          <div className="row mt-lg-5">
            <div className="col-md-12 mt-lg-2">
              <p className="about-describe info-left">
                Contact us for an immediate response. Suraskha Security & Allied
                Services, provides tailor made security solutions combining
                electronic gadgetry and security personnel. We have successfully
                covered events like Movies Premiere, Beauty Pageants, Concerts,
                Live Shows, Award Ceremonies, Fashion Shows, Beer and Wine
                Festival, Product Launch, Corporate Events, Marriages, Private
                Parties, Destination Weddings and every such events whether big
                or small.
              </p>
            </div>
          </div>

          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Why are we most preferred event security management
                      company in Mumbai?
                    </h2>
                    <p className="feature-course-desc">
                      We have a completely fresh and unique approach to handling
                      security for events in India. We understand what it takes
                      to secure the event and make it a grand success. When you
                      hire event security from other companies, usually you
                      would be provided with manpower without taking care of
                      ground level activity and thereby forcing you to handle,
                      give instructions, coordinate and monitor the entire
                      event. In our case, our work does not end by just
                      allotting you required personnel, we will ensure that your
                      event is handled professionally and smoothly by allotting
                      you an assignment manager. Our Assignment Manager will
                      take care of all ground level activity from commencement
                      to conclusion and will be your single point of contact for
                      your entire event.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image mt-lg-5">
                      <br />
                      <br />
                      <img src="assets/img/event1.jpg" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Suraskha Security & Allied Services has served us on many
                  occasions by providing guards as per our requirement. Their
                  service is as per our expectation.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We have been regularly hiring Suraskha Security & Allied
                  Services, for many of our events. We recommend their service
                  to anyone looking to hire security for their events.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EventSecurity