import React from "react";
import Testimonial from "../home/Testimonial";

const About = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img//img/40.jpeg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">About us</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="title-divider-center" />
            <h2 className="about-title text-center ">
              Welcome to Suraskha Security & Allied Services{" "}
            </h2>

            <div className="mt-lg-2">
              <figure className="">
                <img src="assets/img/img/about.jpg" alt />
              </figure>
            </div>
            <div className="row mt-lg-5">
              <div className="col-md-6 mt-lg-4">
                <p className="about-describe info-left">
                  Suraksha Security and Allied Services was established in 2018-19 with an aim to provide Security
                  services to various establishments. Over the years we have gained specialisation in providing
                  integrated security and housekeeping solutions to various establishments including but not limited to
                  Residential societies, commercial complexes, Construction sites, factories, infra projects, toll ways,
                  malls, hospitals, educational institutes, hotels and resorts etc. Further we have a proven track record of
                  completing various operations and providing resolutions in matters of land acquisitions, labour issues,
                  private espionage. The motto of the group is to provide "All services under one roof" with respect to man
                  power to further this vision we are now able to provide housekeeping and tech support with analytics to
                  our customers spread across the country.
                </p>
                {/* <p className="about-describe info-left">
                  Suraskha Security & Allied Services a Leading Security Company
                  in India, was Established in 2023 and ranks amongst the
                  Leading Security Services Company in India. It is the
                  Camaraderie of Our People that we strive to deliver Services
                  that we offer and ensure that you and your property is
                  Safeguarded at all times. We at Suraskha Security & Allied
                  Services are pleased to introduce ourselves as professional
                  security and facility services organization. We are engaged in
                  providing all security Services under one roof along with the
                  allied activities pertaining to all aspects.We Provide
                  Security Guards, Armed, Trained, Executive Security Guards,
                  Project and Event Security Guards,Hospital Security,
                </p> */}
              </div>
              <div className="col-md-6 mt-lg-4">
                <p className="about-describe info-right">
                  Lady Security Guards,Industrial Security, Bank Security,Office
                  Security, Drivers Valet Parking Services, Housekeeping. These
                  Cost Effective services successfully meet the desired
                  requirement of our Clients. We are pioneers in rendering the
                  services to Commercial Establishment plants, Factories,
                  Multiplexes, Residential Complexes, Construction Sites, and
                  Banks. Our Core team has very rich experiences in this field
                  and has excellent Contacts all over. We provide the best
                  facility management and Security Guards services to the
                  business concerns of Mumbai City. We provide our services to
                  almost every big corporate entity of the city. The group is
                  managed by professionals who have several years of experience
                  in their respective fields.
                </p>
              </div>
            </div>
            <div className="counter-inner">
              <div className="counter-item-wrap row">
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">2500</span>+
                  </div>
                  <div className="Completed">Happy Clients</div>
                </div>
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">50</span>+
                  </div>
                  <div className="Completed">House Keeping</div>
                </div>
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">100</span>%
                  </div>
                  <div className="Completed">Reviews</div>
                </div>
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">25</span>+
                  </div>
                  <span className="Completed">Award Achieved</span>
                </div>
              </div>
            </div>
            <div className="about-banner">
              <figure className="about-image figure-round-border">
                <img src="assets/img/img/23.jpeg" alt />
              </figure>
              <div className="about-left-banner">
                <div className="banner-icon">
                  <i aria-hidden="true" className="icon icon-trophy1" />
                </div>
                <h3>10+ YEARS OF EXPERIENCE</h3>
              </div>
              <div className="about-right-banner">
                <h5>Core Values</h5>
                <ul>
                  <li>Securing Nation Years Ahead.</li>
                  <li>Intelligence to strive & deliver.</li>
                  <li>Intensity with a strong & passionate drive.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="vacancy-content-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="vacancy-content">
                    <div className="pattern-overlay zigzag-patten" />
                    <h4 className="text-center">Our Vision</h4>
                    <p>
                      We are committed to the ongoing improvement of the
                      services we provide to our clients. By investing in and
                      developing our most important assets, we aim to achieve
                      all our goals and exceed our client's expectations. It is
                      our vision to earn the trust of our clients by delivering
                      the best quality services within India.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vacancy-content">
                    <h4 className="text-center"> Our Mission</h4>
                    <p>
                      Our mission is to provide best quality services as per our
                      customer needs by delivering highest quality of
                      professional private security service, trasportation
                      service and as well as facility service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </>
  );
};

export default About;
