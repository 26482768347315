import React from "react";

const FemaleSecurityGuard = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Female Security Guard</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Role of Female Guard
                    </h2>
                    <p className="feature-course-desc">
                      In many situations, a female security guard is essential
                      for securing the site. A women is often seen as a friendly
                      face and as such tends to portray a different image to
                      that of the stereo-typical male security guard. Due to
                      this, what could at first be seen as an hostile situation,
                      often has the opposite effect when dealt with by a female
                      security guard. In recent times, there is spike in demand
                      of lady searcher in Mumbai as frisking has become core
                      part of security at many places.
                    </p>
                    <p className="feature-course-desc">
                      A lady security guard is ideal for places where visitors
                      are mostly female and children. But, role of Female
                      Security Guard is not limited to frisking female visitors,
                      they can undertake almost all the security related task
                      that a male security guard does with equal efficiency and
                      dedication. Presence of lady guard gives a sense of
                      security to woman patrons.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <img src="assets/img/img/Female Guard.jpeg" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Female staff deployed by you has been an asset to our school
                  and they are performing their job with lots of energy,
                  enthusiasm and concerns for our young pupils.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                    <span className="author-title">R.K. Public School</span>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “To all the security guards, we would like to express our
                  sincere esteem for your extraordinary efforts during these
                  pandemic times. Your self motivation, dedication and diligence
                  is an inspiration to all of us. Thank you for your selfless
                  succour and contributions.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                    <span className="author-title">CEO (Noble Hospital)</span>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FemaleSecurityGuard;
