import React from "react";
import { Link } from "react-router-dom";


const Consultant = () => {
  return (
    <>
      <section className="home-team-section" style={{ overflow: "hidden" }}>
        <div className="container">
          <div className="overlay" />
          <div className="row align-items-end">
            <div className="col-md-6 text-md-start text-center">
              <div className="title-divider" />
              <h2 className="team-section-title">Our Best Consultant</h2>
            </div>
          </div>
          <div className="group-member">
            <div className="row">


              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="team-img">
                  <img src="assets/img/img/Gurunath Sharma.JPEG" alt />
                </figure>
                <div className="team-content text-center">
                  <h4>
                    <Link to=""> Mr. Gurunath Sharma</Link>
                  </h4>
                  <p className="text-center">Rohit Sharma (Cricketer Parents)</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="social-links team-social-link">
                    <ul>
                      <li>
                        <Link to="">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="team-img">
                  <img src="assets/img/img/Mr Dhirendra Pratap Singh.JPEG" alt />
                </figure>
                <div className="team-content text-center">
                  <h4>
                    <Link to="">Mr Dhirendra Pratap Singh</Link>
                  </h4>
                  <p className="text-center">Security Trainer</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="social-links team-social-link">
                    <ul>
                      <li>
                        <Link to="">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="team-img">
                  <img src="assets/img/img/Ram Bahadur Pal.jpg" alt />
                </figure>
                <div className="team-content text-center">
                  <h4>
                    <Link to=""> Mr. Ram Bahadur Pal</Link>
                  </h4>
                  <p className="text-center">Advocate</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="social-links team-social-link">
                    <ul>
                      <li>
                        <Link to="">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="team-img">
                  <img src="assets/img/Mr.Ranvijay Singh Pal.png" alt />
                </figure>
                <div className="team-content text-center">
                  <h4>
                    <Link to="">Mr.Ranvijay Singh Pal </Link>
                  </h4>
                  <p className="text-center">Advocate</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="social-links team-social-link">
                    <ul>
                      <li>
                        <Link to="">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Consultant;
