import React from 'react'

const LabourConsultants = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Labour Consultants</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Abot Labour Consultants Services
                    </h2>
                    <p className="feature-course-desc">
                      Labour consultants help businesses ensure compliance with
                      relevant labour laws and regulations. They assist in
                      developing employment policies, procedures, and contracts
                      that align with legal requirements. They also provide
                      guidance on issues such as minimum wage, working hours,
                      overtime, and employee benefits.
                    </p>
                    <p className="feature-course-desc">
                      Consultants offer guidance on maintaining positive
                      employee relations to foster a healthy work environment.
                      They can assist with conflict resolution, disciplinary
                      actions, grievances, and collective bargaining
                      negotiations with labour unions. Their expertise helps
                      businesses mitigate risks and maintain productive
                      relationships with their workforce.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <br />
                      <br />
                      <br />
                      <img
                        src="assets/img/img/Labour Consultants Services.png"
                        alt="p"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LabourConsultants