import React from "react";
import { Link } from "react-router-dom";

const ItTime = () => {
  return (
    <>
      <section className="home-fact-section mt-lg-5" style={{overflow:"hidden"}}>
        <div className="overlay" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="fact-section-head">
                <div className="title-divider" />
                <h2 className="fact-section-title">
                  it’s time to upgrade your security Switch to Suraskha Security
                  & Allied Services !
                </h2>
                <p className="fact-section-info">
                  We provide highly dedicated & trained security services for
                  various commercial and industrial sectors
                </p>
                <div className="service-btn">
                  <Link to="/contact" className="button-round-secondary">
                    JOIN US NOW
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fact-right-inner-wrap">
                <div className="fact-inner-box">
                  <div className="fact-content top-left-content">
                    <div className="pattern-overlay circle-patten" />
                    <h4 className="">Our Expertise Is Best Earned Through Our Experience</h4>
                  </div>
                  <figure className="fact-image top-right-image" />
                  <figure className="fact-image bottom-left-image" />
                  <div className="fact-content bottom-right-content">
                    <div className="pattern-overlay circle-c-patten" />
                    <h4>Our Best Team For Your Any Advice For Your Security</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItTime;
