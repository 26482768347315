import React from 'react'

const OfficeSecurity = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Office Security</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <div className="title-divider-center mt-lg-5" />
          <h2 className="about-title text-center  ">
            Security Service for Corporates in India
          </h2>
          <div className="row mt-lg-5">
            <div className="col-md-12 mt-lg-4">
              <p className="about-describe info-left">
                Role of a Security Personnel working in office has undergone
                drastic change. Not a long ago, security guard in office was
                primarily deployed to manned the gate. But over the last decade
                or so, there is a complete changeover of role of security in a
                corporate office in Mumbai. In Today’s time, security team is
                expected to follow the SOP set up by the client and carry out
                number of important task for the safety and security of office
                premises.
              </p>
            </div>
          </div>
          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Suraskha Security & Allied Services is serving us since 2021
                  and we find their service up to the mark and satisfactory. We
                  wish them all the luck for their endeavour and looking forward
                  to take this business relationship for many more years to
                  come. We are happy to recommend their security service.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Your professionalism reflects in the countless hours and days
                  that you laid to keep our facilities and business operating
                  People like you who prefer to go that extra mile makes
                  everything possible. Your extraordinary support deserves our
                  unending gratitude. You all are setting an extraordinary
                  example of commitment, professionalism and solidarity. You are
                  the best guarantee that, united, we will emerge from this
                  crisis stronger.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OfficeSecurity