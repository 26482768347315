import React from "react";

const Gallery = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.webp)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Gallery</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="product-outer-wrap product-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 right-sidebar">
              <div className="product-item-wrapper">
                <div className="row">
                  {/* <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/24.GIF" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div> */}
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/2.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/4.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/b4.jpeg" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>

                  {/* tejbhan */}
                 
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/07.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/08.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/105.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/b1.png" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/b2.png" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/b3.png" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/106.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/02.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/110.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>


                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/01.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/03.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/04.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/05.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/06.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/112.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>

                  {/* GIF */}
                  {/* <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/gif.GIF" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/101.gif" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="/assets/img/Gallery/102.gif" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/104.GIF" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/112.gif" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/114.gif" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div> */}



                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/3.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/5.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/6.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/7.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/8.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/14.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/10.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/11.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/12.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/13.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/9.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/16.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/15.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/17.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/18.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/20.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/21.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <a to="">
                          <img src="assets/img/Gallery/22.webp" alt="" />
                        </a>
                      </figure>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
