import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";


const Contact = () => {

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const mobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
  };
  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_vb0u61u",
        "template_36w5wdq",
        "#myForm",
        "pFpBeQ8Vlt-Ty3XMu"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setMobile("");
            setmail("");
            setMassages("");
          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{
              backgroundImage: "url(assets/img/gaurdingservicesbanner.jpg)",
            }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Contact Us</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-section">
          <div className="container">
            <div className="contact-form-inner">
              <div className="pattern-overlay zigzag-patten" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="contact-detail-container">
                    <div className="section-head">
                      <div className="title-divider" />
                      <h2 className="section-title">Contact !</h2>
                    </div>
                    <div className="contact-details-list">
                      <ul>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-phone1"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Phone Number :</h5>

                            <span className="text-center mt-lg-3">

                              <p className="mb-2">
                                <a
                                  href="tel:+919380293380"
                                  style={{ color: "black" }}
                                >
                                  +91-7045365593
                                </a>
                              </p>
                              <p className="mb-2">
                                <a
                                  href="tel:+919630553865"
                                  style={{ color: "black" }}
                                >
                                  +91- 9630553865
                                </a>
                              </p>
                              <p className="mb-2">
                                <a
                                  href="tel:+918965007230"
                                  style={{ color: "black" }}
                                >
                                  +91- 8965007230
                                </a>
                              </p>
                            </span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-map-marker1"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Location Address :</h5>
                            <span className="mt-lg-3">
                              2209 Bldg No.1 B (s+24) - 286 tenements, Cabin -2,
                              old Morarji Mill Comp, Ashok Chakravarti Marg,
                              Ashok Nagar, Kandivali (East) Mumbai Maharashtra
                            </span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-envelope3"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Email address :</h5>
                            <a style={{ color: "black" }} href="mailto:surakshasec22@gmail.com">surakshasec22@gmail.com</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-from-wrap">
                    <div className="pattern-overlay circle-patten" />
                    <h5 className="cheakout-title text-center">
                      Fill in your details and we’ll call you back.
                    </h5>

                    <form className="contact-from"
                      id="myForm" onSubmit={sendEmail} action=""
                      method="post"
                    >
                      <p>
                        <input
                          type="text"
                          placeholder="First Name*"
                          name='from_name'
                          value={name}
                          onChange={(e) => nameChange(e)}
                          required
                        />
                      </p>
                     
                      <p>
                        <input
                          type="number"
                          name="Your Phone"
                          placeholder=" Number*"
                          value={mobile} onChange={(e) => mobileChange(e)}
                          required

                        />
                      </p>
                      <p>
                        <input type="email" placeholder="Email*"
                          name="mail" value={mail} onChange={(e) => mailChange(e)}
                          required

                        />

                      </p>
                      <p>
                        <input
                          type="text"
                          name="message"
                          placeholder="Message*"
                          value={massages} onChange={(e) => massagesChange(e)}
                          required

                        />
                      </p>
                      <a className="width-full text-center" href="">
                        <input
                          type="submit"
                          name="submit"
                          defaultValue="Submit Now"
                        />
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Contact;
