import React from 'react'

const Certificate = () => {
  return (
    <>
      <section className="home-about-us" style={{overflow:"hidden"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="" />
              <h2 className="section-title text-center">Licence & Awrad !</h2>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-6 ">
              <div className="about-left-content">
                <figure className="figure-round-border">
                  <img src="assets/img/img/Certificate-1.jpeg" alt="" />
                </figure>

                <div className="title-divider" />
              </div>
            </div>

            <div className="col-md-6">
              <div className=" about-right-content">
                <br />
                <h6 className="about-title">Certificate</h6>
                <p>
                  It's essential to ensure that the institution or organization
                  offering the certificate program is reputable and accredited.
                  Accreditation ensures that the program meets specific quality
                  standards and that the certificate is recognized and valued by
                  employers or other educational institutions.
                </p>
                <figure className="figure-round-border">
                  <img src="assets/img/img/Certificate.jpeg" alt="" />
                </figure>
                <br />
              </div>
            </div>
          </div>
          <div className="pattern-overlay" />
        </div>
      </section>
    </>
  );
}

export default Certificate