import React from 'react'

const BankSecurity = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Bank Security</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Role of Bank Security Guard
                    </h2>
                    <p className="feature-course-desc">
                      Banking sector was the first industry where professional
                      security was deployed in India. Security of Banks and
                      Financial Institutions are of paramount importance for any
                      nation and private security companies are playing critical
                      role in securing these nation’s treasure.
                    </p>
                    <p className="feature-course-desc">
                      Banks secure their premises with the help of CCTV,
                      Intruder Alarm System, Strong Room (locker) and most
                      importantly through physical deployment of armed or
                      unarmed guards. Being alert and agile are the two most
                      important quality of a security guard deployed at the
                      bank. Mere presence of security guard deter anyone from
                      harming the bank and ATM. A security guard should safe
                      guard the bank premises to the best of his ability and if
                      the situation goes out of control, he should immediately
                      dial 100 and call the police.
                    </p>
                    {/* <p className="feature-course-desc">
                      We provide training to guards deployed at the Bank to make
                      them understand their role in unfortunate incident of
                      robbery. Usage of code words and controlling the entrance
                      of the bank, safeguard the bank at the time of robbery.
                      Security of teller counter on daily basis is vital and
                      guard need to ensure that no unauthorized person enter the
                      protected zone. We conduct brief training session for
                      entire branch of the bank to make them aware of the
                      presence of threat and how to tackle it.
                    </p> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <br />
                      <br />
                      <img src="assets/img/img/Bank Security.jpg" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Suraskha Security & Allied Services, Private Limited has been
                  providing us security service from 2022 at our various
                  branches in India and we are pleased with their overall
                  service level.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We are satisfied with your service and recommend your service
                  to others.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BankSecurity