import React from 'react'

const HouseKeepingServices = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">House Keeping Services</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      House Keeping Services
                    </h2>
                    <p className="feature-course-desc">
                      Apart from above, we provide bouquet of other services
                      which includes carpenter, plumber, electrician, book
                      binder, pump operator and fitter. Large organisation
                      prefers to hire technician on annual contract basis
                      instead of calling them on need basis to attend piecemeal
                      job. In house technicians helps an organisation in
                      upkeeping the facility 365 days a year without any
                      breakdown. Our technician service is currently available
                      in Mumbai only.
                    </p>
                    <p className="feature-course-desc">
                      Suraskha Security & Allied Services, has very professional
                      approach towards facility management services and employ
                      only qualified technician with ITI course and who are
                      bestowed with National Trade Certificate. Our expert staff
                      will work exclusively under your command and control. Our
                      team of qualified facility staff are capable of taking
                      care of your daily maintenance and other requirement
                      without the need of your supervision and intervention. We
                      are top Facility Management Service provider in Mumbai.
                      You can hire our facility management Service on annual
                      contract basis or as per your need.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <br />
                      <br />
                      <br />
                      <img src="assets/img/img/housekeeping.png" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We have hired security and housekeeping service from Suraskha
                  Security & Allied Services, Services for our various projects
                  and we are pleased to inform that the service is satisfactory
                  and prompt. We recommend their service to their prospective
                  clients.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Suraskha Security & Allied Services, has provided us security
                  and housekeeping service at our office and we are very pleased
                  with their service level.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HouseKeepingServices