import React from "react";
import { Link } from "react-router-dom";


const HomeServices = () => {
  return (
    <>
      <section className="home-course-section" style={{overflow:"hidden"}}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="title-divider" />
                <h2 className="section-title">Security Services</h2>
                <p className="course-section-info">
                  Depending on the size, location, nature and requirements of
                  the client’s business, Suraskha Security & Allied Services
                  customises it’s solution after understanding client’s pain
                  points.
                </p>
              </div>
            </div>
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/s3.JPG" alt />
                      <Link to="/securityguard" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/securityguard">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/securityguard">Security Guard</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/23.JPEG" alt />
                      <Link to="/femalesecurityguard" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/femalesecurityguard">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/femalesecurityguard">
                          Female Security Guard
                        </Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/event.jpg" alt />
                      <Link to="/eventsecurity" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/eventsecurity">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/eventsecurity">Event Security </Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/19.JPEG" alt />
                      <Link to="/officesecurity" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/officesecurity">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/officesecurity">Office Security</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/30.jpeg" alt />
                      <Link to="/buildingsecurity" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/buildingsecurity">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/buildingsecurity">Building Security</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/22.jpeg" alt />
                      <Link to="/banksecurity" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/banksecurity">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/banksecurity">Bank Security</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/37.jpeg" alt />
                      <Link to="/industrialsecurity" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/industrialsecurity">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/industrialsecurity">
                          Industrial Security
                        </Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/hospital.jpg" alt />
                      <Link to="/hospitalsecurity" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/hospitalsecurity">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/hospitalsecurity">Hospital Security</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/img/4.jpeg" alt />
                      <Link to="/firesafety" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/firesafety">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/firesafety">Fire Safety</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                {/* <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/Cash Management.jpg" alt />
                      <Link to="/cashmanagement" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/cashmanagement">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/cashmanagement">Cash Management</Link>
                      </h4>
                    </div>
                  </article>
                </div> */}
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/Housekeeping Services.jpg" alt />
                      <Link
                        to="/housekeepingservices"
                        className="bookmark-icon"
                      >
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/housekeepingservices">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/housekeepingservices">
                          Housekeeping Services
                        </Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/Labour Consultants.png" alt />
                      <Link to="/labourconsultants" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/labourconsultants">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="/labourconsultants">Labour Consultants</Link>
                      </h4>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
