import React from "react";
import HomeBanner from "./home/HomeBanner";
import HomeAbout from "./home/HomeAbout";
import Committed from "./home/Committed";
import ItTime from "./home/ItTime";
import HomeServices from "./home/HomeServices";
import Consultant from "./home/Consultant";
import Testimonial from "./home/Testimonial";
import Clients from "./home/Clients";
import Certificate from "./home/Certificate";
import HomeBanner1 from "./home/HomeBanner1";

const Home = () => {
  return (
    <>
      {/* <HomeBanner /> */}
      <HomeBanner1 />
      <HomeAbout />
      <Certificate />
      <ItTime />
      <HomeServices />
      <Consultant />
      <Committed />
      <Testimonial />
      <Clients />
    </>
  );
};

export default Home;
