import React from 'react'

const IndustrialSecurity = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Industrial Security</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Role of Industrial Security Guard
                    </h2>
                    <p className="feature-course-desc">
                      A manufacturing unit’s activity is complex as compared to
                      service and trading industry. Beside the complex nature of
                      production, a factory needs to comply with number of acts,
                      rules and regulation like Factory Act, Pollution Control
                      Act, Contract Labour Act, etc. Similarly, securing a
                      factory involves many challenges on day to day basis. Risk
                      of loss by theft and pilferage is highest at a factory as
                      compared to other sites. Therefore, role of security is
                      very critical in mitigating the risk faced by industrial
                      unit.
                    </p>
                    <p className="feature-course-desc">
                      We provide additional service of Undercover Agent under
                      our detective division. If your company is suffering from
                      abnormal wastage, pilferages, stock loss, security lapses,
                      employee theft, employees having nexus with vendors, union
                      related problems, workers creating nuisance with or
                      without help of locals or any such related activity, then
                      we can help you out in unearthing the real cause. An
                      undercover operation gives the floor-level,
                      down-in-the-trenches viewpoint management needs to ferret
                      out activity in the workplace that can lead to corporate
                      losses
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <img src="assets/img/img/21.jpeg" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We are satisfied with your service and timely meeting of our
                  concerns for the security of our warehouse.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We have deployed Suraskha Security & Allied Services, from
                  2022 and continue to see many positive changes in our entire
                  security setup.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default IndustrialSecurity