import React from "react";
// import ClientsSlider from "./components/ClientsSlider";
import { Link } from "react-router-dom";


const Clients = () => {
  return (
    <>
      {/* <div className="slider-section">
        <div className="container">
          <div className="client-slider text-center">
            <ClientsSlider/>
            
          </div>
        </div>
      </div> */}

      <section className="home-team-section bg-white" style={{ overflow: "hidden" }}>
        <div className="container">
          <div className="overlay" />
          <div className="row align-items-end">
            <div className="col-md-6 text-md-start text-center">
              <div className="title-divider" />
              <h2 className="team-section-title">Clients</h2>
            </div>
          </div>
          <div className="group-member">
            <div className="row">
              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="">
                  <img src="assets/img/img/client.jpeg" alt />
                </figure>
              </div>
              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="">
                  <img src="assets/img/img/client1.webp" alt />
                </figure>
                <br />
                <figure className="">
                  <img src="assets/img/img/hunar.webp" alt />
                </figure>
              </div>
              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="">
                  <img src="assets/img/img/madhuri.png" alt />
                </figure>
                <br />
                <figure className="">
                  <img src="assets/img/img/sp.png" alt />
                </figure>
              </div>
              <div className="col-sm-6 col-lg-3 p-4">
                <figure className="">
                  <img src="assets/img/img/ekta.png" alt />
                </figure>
                <figure className="">
                  <img src="assets/img/img/XM.png" alt />
                </figure>
              </div>
            </div>
          </div>
          <div>
            <figure className="">
              <img src="assets/img/img/Add a subheading.png" alt />
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
