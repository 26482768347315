import React from 'react'

const SecurityGuard = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Security Guard</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <div className="title-divider-center mt-lg-5" />
          <h2 className="about-title text-center  ">
            Manned Guarding Service – Armed and Unarmed
          </h2>
          <div className="row mt-lg-5">
            <div className="col-md-12 mt-lg-4">
              <p className="about-describe info-left">
                Security Guard is omnipresent and can be found anywhere, be it a
                commercial establishment or a residential unit. They have become
                an indispensable part of our society. Security Guard is highly
                effective in protecting life and property which cannot be
                replaced by any electronic gadget. We are amongst the best
                security guard provider in Mumbai.
              </p>
            </div>
          </div>

          <div className="carrer-page-section">
            <div className="container">
              <div className="vacancy-section">
                <div className="vacancy-container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="vacancy-content-wrap">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="vacancy-content">
                              <div className="pattern-overlay zigzag-patten" />
                              <h4 className="text-center">Security Guard</h4>
                              <p className="">
                                A Security Guard in the Private Security Sector
                                form the first level of defence, who notices and
                                encounters threats and risks that are
                                detrimental to life, property and premises.
                                Security Guard is responsible for monitoring
                                premises and property through physical presence
                                and by using security and protection systems. We
                                are consistently ranked as top security guard
                                provider in Mumbai for providing high quality
                                security guards.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="vacancy-content">
                              <div className="pattern-overlay c-patten" />
                              <h4 className="text-center">Armed Guard</h4>
                              <p>
                                An Armed guard is preferred when threat
                                perception or risk factor is higher and guarding
                                of valuables or VIP is required. Presence of
                                guard with armed, work as a deterrent and
                                prevents crime. Arm guard is required to use his
                                weapon judiciously to protect life and property.
                                We provide professional, trained, disciplined
                                and uniformed security to take care of your
                                business in most efficient and economical way.
                                If you are looking to create your own army of
                                security or upgrade your existing security
                                setup, within an economical budget, call us for
                                a free consultation and free (non committed)
                                quote.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="vacancy-content">
                              <div className="pattern-overlay c-patten" />
                              <h4 className="text-center">Security Officer</h4>
                              <p>
                                A Security Officer is required when the strength
                                of security personnel is higher and complex job,
                                such as handling entire unit on its own,
                                training on regular basis, reporting writing,
                                etc., is involved. Security officer is in charge
                                of entire security operation of a unit. He
                                reports to operation manager.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="vacancy-content">
                              <div className="pattern-overlay c-patten" />
                              <h4 className="text-center">
                                {" "}
                                Security Supervisor
                              </h4>
                              <p>
                                Security Supervisor in the Private Security
                                Sector is the first executive, who apart from
                                his own duties has operational and
                                administrative responsibilities towards a
                                security unit. He allots duties to security
                                guards and supervise their day to day work. He
                                reports to security officer.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “Your security guard Mr. R.K. soni has shown great presence of
                  mind and excellent ability to see through different situations
                  thereby saving our valuable merchandise.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                    <span className="author-title">
                      Phoenix Hospital Pvt Ltd.
                    </span>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “To all the security guards, we would like to express our
                  sincere esteem for your extraordinary efforts during these
                  pandemic times. Your self motivation, dedication and diligence
                  is an inspiration to all of us. Thank you for your selfless
                  succour and contributions.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                    <span className="author-title">CEO (Noble Hospital)</span>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SecurityGuard