import React from "react";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <section className="home-about-us" style={{ overflow: "hidden" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="about-left-content">
                <figure className="figure-round-border">
                  <img src="assets/img/img/10.jpeg" alt />
                </figure>
                <div className="skill-container">
                  <div className="skill-wrapper">
                    <h6 className="skill-titile">Completed Projects</h6>
                    <div className="progress-wrapper">
                      <div
                        className="ab-progress example"
                        data-progress
                        data-value={92}
                      />
                    </div>
                  </div>
                  <div className="skill-wrapper">
                    <h6 className="skill-titile">Running Work</h6>
                    <div className="progress-wrapper">
                      <div
                        className="ab-progress example"
                        data-progress
                        data-value={98}
                      />
                    </div>
                  </div>
                  <div className="skill-wrapper">
                    <h6 className="skill-titile">Relaible &amp; Hardworking</h6>
                    <div className="progress-wrapper">
                      <div
                        className="ab-progress example"
                        data-progress
                        data-value={99}
                      />
                    </div>
                  </div>
                </div>
                <div className="regarding-us">
                  <div className="qulifaction-content left-content">
                    <div className="qualification-tag">
                      <div className="qualifaction-icon">
                        <i aria-hidden="true" className="icon icon-medal1" />
                      </div>
                      <div className="qualifaction-title">
                        <h5> Mission</h5>
                      </div>
                    </div>
                    <div className="qulifaction-detail">
                      <p>
                        A friendly smiling face to welcome visitors to your
                        premises, or a more assertive no-nonsense approach tom
                        protects your business and assets.
                      </p>
                    </div>
                  </div>
                  <div className="qulifaction-content right-content">
                    <div className="qualification-tag">
                      <div className="qualifaction-icon">
                        <i aria-hidden="true" className="icon icon-man" />
                      </div>
                      <div className="qualifaction-title">
                        <h5>Vision</h5>
                      </div>
                    </div>
                    <div className="qulifaction-detail">
                      <p>
                        We have a big vision of being the most admired, trusted
                        and market leader in provision of total security
                        solutions. Starting with small communities, Suraskha
                        Security & Allied Services promises to deliver an
                        unparalleled service which will lead us to achieving
                        this goal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="about-btn">
                  <Link to="about" className="button-round-secondary">
                    MORE ABOUT US
                  </Link>
                </div>


                <div className="mt-lg-5">
                  <figure className="">
                    <img src="assets/img/img/Trainer.jpg" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className=" about-right-content">
                <div className="title-divider" />
                <h2 className="about-title">About Us !</h2>
                <p className="about-desc">
                  Suraskha Security & Allied Services a Leading Security Company
                  in India, was Established in 2023 and ranks amongst the
                  Leading Security Services Company in India. It is the
                  Camaraderie of Our People that we strive to deliver Services
                  that we offer and ensure that you and your property is
                  Safeguarded at all times. We at Suraskha Security & Allied
                  Services are pleased to introduce ourselves as professional
                  security and facility services organization. We are engaged in
                  providing all security Services under one roof along with the
                  allied activities pertaining to all aspects.We Provide
                  Security Guards, Armed, Trained, Executive Security Guards,
                  Project and Event Security Guards,Hospital Security, Lady
                  Security Guards,Industrial Security, Bank Security,Office
                  Security, Drivers Valet Parking Services, Housekeeping. These
                  Cost Effective services successfully meet the desired
                  requirement of our Clients.
                </p>
                <p className="about-desc">
                  We offer professional, world-class and uninterrupted service
                  to our clients and relentlessly follow “Client First”
                  approach. Time and again we have proved that you can 100% rely
                  on us, even during major crisis, without putting your business
                  continuity plan at risk. We are top 10 security companies in
                  India and best security guard services in Mumbai. We are
                  consistently listed as the best private security agency in
                  Mumbai. We are providing Security Service in Mumbai, Pune etc.
                  Our personnel are skilled and trained to keep up with the fast
                  changing business environment. We are top 10 security guard
                  company in Mumbai.
                </p>
                <figure className="figure-round-border">
                  <img src="assets/img/img/shivedra.jpeg" alt />
                </figure>
              </div>
            </div>
          </div>
          <div className="pattern-overlay" />
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
