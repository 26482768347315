import React, { useState } from "react";
import { Link } from "react-router-dom";

const Hadear = () => {
  const [toggle1, setToggle1] = useState(false);
  const [toggleStudy, setToggleStudy] = useState(false);
  const [toggleservices, setToggleservices] = useState(false);
  const [toggletest, setToggletest] = useState(false);
  const [toggleDestinations, setToggleDestinations] = useState(false);

  const CloseClick = () => {
    setToggleStudy(false);
    setToggle1(false);
  };

  return (
    <>
      <div>
        <div id="page" className="full-page">
          <header className="site-header site-header-transparent">
            <div className="top-header">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-8 d-none d-lg-block">
                    <div className="header-contact-info">
                      <ul>
                        <li>
                          <Link to="tel:+9380293380">
                            <i className="fas fa-phone-alt" />
                            +91-7045365593
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt" /> Ashok Nagar,
                          Kandivali (East) Mumbai Maharashtra
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between">
                    <div className="header-social social-links">
                      <ul>
                        <li>
                          <a target="_blank" href="https://www.facebook.com/profile.php?id=61559012982298&mibextid=ZbWKwL">
                            <i className="fab fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <Link to="" target="_blank">
                            <i className="fab fa-twitter" aria-hidden="true" />
                          </Link>
                        </li>
                        <li>
                          <Link to="" target="_blank">
                            <i className="fab fa-youtube" aria-hidden="true" />
                          </Link>
                        </li>
                        <li>
                          <Link to="" target="_blank">
                            <i
                              className="fab fa-instagram"
                              aria-hidden="true"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="" target="_blank">
                            <i className="fab fa-linkedin" aria-hidden="true" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-header" id="masthead">
              <div className="container">
                <div className="hb-group d-flex align-items-center justify-content-between">
                  <div className="site-identity col-lg-3">
                    <p className="site-title">
                      <Link to="">
                        <img
                          src="assets/img/img/Suraskha_Security.png"
                          alt="logo"
                        />
                      </Link>
                    </p>
                  </div>
                  <div className="main-navigation col-lg-9 justify-content-between d-flex align-items-center">
                    <nav
                      id="menu "
                      className="navigation d-none d-lg-inline-block collapse navbar-collapse"
                    >
                      <ul>
                        <li className="current-menu-item">
                          <Link to="/">Home</Link>
                        </li>

                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link to="#">Services</Link>
                          <ul>
                            <li>
                              <Link to="/securityguard">Security Guard</Link>
                            </li>
                            <li>
                              <Link to="/femalesecurityguard">
                                {" "}
                                Female Security Guard
                              </Link>
                            </li>
                            <li>
                              <Link to="/eventsecurity"> Event Security</Link>
                            </li>
                            <li>
                              <Link to="/officesecurity"> Office Security</Link>
                            </li>
                            <li>
                              <Link to="/buildingsecurity">
                                {" "}
                                Building Security
                              </Link>
                            </li>
                            <li>
                              <Link to="/banksecurity"> Bank Security</Link>
                            </li>
                            <li>
                              <Link to="/industrialsecurity">
                                {" "}
                                Industrial Security
                              </Link>
                            </li>
                            <li>
                              <Link to="/hospitalsecurity">
                                {" "}
                                Hospital Security
                              </Link>
                            </li>
                            <li>
                              <Link to="/firesafety"> Fire Safety</Link>
                            </li>
                            {/* <li>
                              <Link to="/cashmanagement"> Cash Management</Link>
                            </li> */}
                            <li>
                              <Link to="/housekeepingservices">
                                {" "}
                                Housekeeping Services
                              </Link>
                            </li>
                            <li>
                              <Link to="/labourconsultants">
                                {" "}
                                Labour Consultants
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/contact">Carrers</Link>
                        </li>

                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                          <a href="assets/img/img/BROUCHERS.pdf" download target="_blank"
                          >Brouchers</a>
                        </li>
                      </ul>
                    </nav>
                    <div className="joinusabcde">
                      <div className="header-btn d-inline-block">
                        <a href="assets/img/img/BROUCHERS.pdf" className="button-round-secondary" target="_blank"
                        download
                        >
                        Brouchers
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-menu-container">
              <div className="slicknav_menu">
                {toggle1 === false ? (
                  <>
                    <Link
                      to="#"
                      aria-haspopup="true"
                      role="button"
                      tabIndex={0}
                      className="slicknav_btn slicknav_collapsed"
                    >
                      <span
                        className="slicknav_icon"
                        onClick={() => setToggle1(!toggle1)}
                      >
                        <span
                          className="slicknav_icon-bar"
                          onClick={() => setToggle1(!toggle1)}
                        />
                      </span>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="#"
                      aria-haspopup="true"
                      role="button"
                      tabIndex={0}
                      className="slicknav_btn slicknav_collapsed"
                    >
                      <span
                        className="slicknav_icon"
                        onClick={() => setToggle1(!toggle1)}
                      >
                        <h4 onClick={() => setToggle1(!toggle1)}>X</h4>
                      </span>
                    </Link>
                  </>
                )}

                {toggle1 === true ? (
                  <>
                    <nav
                      className="slicknav_nav slicknav_hidden"
                      aria-hidden="true"
                      role="menu"
                      id="toggle"
                    >
                      <ul>
                        <li
                          className="current-menu-item"
                          onClick={() => setToggle1(!toggle1)}
                        >
                          <Link to="/" role="menuitem">
                            HOME
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/about"
                            role="menuitem"
                            onClick={() => setToggle1(!toggle1)}
                          >
                            About
                          </Link>
                        </li>

                        <li className="menu-item-has-children slicknav_collapsed slicknav_parent">
                          <span
                            className="slicknav_parent-link slicknav_row"
                            onClick={() =>
                              setToggleDestinations(!toggleDestinations)
                            }
                          >
                            <Link to="#"> Services</Link>
                            <Link
                              to="#"
                              role="menuitem"
                              aria-haspopup="true"
                              tabIndex={-1}
                              className="slicknav_item"
                              onClick={() =>
                                setToggleDestinations(!toggleDestinations)
                              }
                            >
                              <span
                                className="slicknav_arrow"
                                onClick={() =>
                                  setToggleDestinations(!toggleDestinations)
                                }
                              >
                                <i className="fas fa-plus" />
                              </span>
                            </Link>
                          </span>

                          {toggleDestinations === true ? (
                            <>
                              <ul
                                role="menu"
                                className="slicknav_hidden"
                                aria-hidden="true"
                              >
                                <li>
                                  <Link
                                    to="/securityguard"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Security Guard
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/femalesecurityguard"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Female Security Guard
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/eventsecurity"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Event Security
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/officesecurity"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Office Security
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/buildingsecurity"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Building Security
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/banksecurity"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Bank Security
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/industrialsecurity"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Industrial Security
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/hospitalsecurity"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Hospital Security
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/firesafety"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Fire Safety
                                  </Link>
                                </li>
                                {/* <li>
                                  <Link
                                    to="/cashmanagement"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Cash Management
                                  </Link>
                                </li> */}
                                <li>
                                  <Link
                                    to="/housekeepingservices"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Housekeeping Services
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/labourconsultants"
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => CloseClick()}
                                  >
                                    Labour Consultants
                                  </Link>
                                </li>
                              </ul>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>

                        <li>
                          <Link
                            to="/gallery"
                            role="menuitem"
                            onClick={() => setToggle1(!toggle1)}
                          >
                            Gallery
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/contact"
                            role="menuitem"
                            onClick={() => setToggle1(!toggle1)}
                          >
                            Carrers
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/contact"
                            role="menuitem"
                            onClick={() => setToggle1(!toggle1)}
                          >
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default Hadear;
