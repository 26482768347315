import React from "react";

const HospitalSecurity = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Hospital Security</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Role of Hospital Security Guard
                    </h2>
                    <p className="feature-course-desc">
                      Security of a Hospital is very sensitive and critical
                      matter. It often involves dealing with distress people.
                      While dealing with visitors, who are relatives of
                      patients, security needs to be empathic and at the same
                      time firm. If visitor are not handled properly, it may
                      result in chaotic situation and end up disturbing the
                      peace and tranquility of the hospital.
                    </p>
                    <p className="feature-course-desc">
                      We understand the critical nature of guarding the hospital
                      premises and its patients, doctors, staffs and visitors.
                      At Suraskha Security & Allied Services, we take care of
                      all the security needs of the hospital in most
                      comprehensive manner.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <img src="assets/img/img/hospital.png" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HospitalSecurity;
