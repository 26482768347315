import React from 'react'

const FireSafety = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Fire Safety</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="" />
          <section className="course-result-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 left-content">
                  <div className="pattern-overlay zigzag-patten" />
                  <div className="feature-left-content">
                    <div className="title-divider" />
                    <h2 className="feature-course-title">
                      Abot Fire Safety Services
                    </h2>
                    <p className="feature-course-desc">
                      Fire prevention focuses on reducing the likelihood of
                      fires occurring. This involves maintaining a safe
                      environment, identifying and addressing potential fire
                      hazards, and implementing preventive measures such as
                      proper storage of flammable materials, regular equipment
                      maintenance, and adherence to safety regulations.
                    </p>
                    <p className="feature-course-desc">
                      Early detection of fires is crucial to initiate prompt
                      response and minimize damage. Fire detection systems
                      include smoke detectors, heat sensors, fire alarms, and
                      sprinkler systems. These systems can automatically detect
                      signs of fire or smoke and alert occupants or authorities,
                      allowing for timely evacuation or firefighting efforts.
                    </p>
                    <p className="feature-course-desc">
                      Establishing an emergency plan is essential to ensure an
                      organized and effective response in case of a fire. This
                      includes creating evacuation procedures, designating
                      assembly points, and conducting regular fire drills to
                      familiarize occupants with the escape routes and emergency
                      protocols.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-right-content">
                    <div className="pattern-overlay c-patten" />
                    <div className="pattern-overlay circle-patten" />
                    <figure className="feature-image">
                      <br />

                      <img src="assets/img/img/8.jpeg" alt="p" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FireSafety