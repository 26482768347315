import React from 'react'

const BuildingSecurity = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/img/38.jpeg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Building Security</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <div className="title-divider-center mt-lg-5" />
          <h2 className="about-title text-center  ">
            Security Service of Building – Commercial and Residential
          </h2>
          <div className="row mt-lg-5">
            <div className="col-md-12 mt-lg-4">
              <p className="about-describe info-left">
                Security of a building has undergone a complete makeover from
                being a chaukidhar to a professional and trained security guard.
                Security guards deployed in a building plays a key role in
                securing the life and assets of the residents and thereby
                securing the community and city as a whole. Police also
                appreciate the fact that contribution of private security in
                societies has played a key role in reducing the crime rate and
                work load of the police.
              </p>
            </div>
          </div>

          <div className="carrer-page-section">
            <div className="container">
              <div className="vacancy-section">
                <div className="vacancy-container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="vacancy-content-wrap">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="vacancy-content">
                              <div className="pattern-overlay zigzag-patten" />
                              <h4 className="text-center">
                                Commercial Building Security
                              </h4>
                              <p className="">
                                With change in office culture, commercial
                                building aesthetics has also undergone complete
                                makeover. A modern commercial complex is
                                equipped with reception, lobby, multi level
                                parking, health club, CCTV camera, fire alarm
                                system, visitor management system, etc.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="vacancy-content">
                              <div className="pattern-overlay c-patten" />
                              <h4 className="text-center">
                                Residential Building Security
                              </h4>
                              <p>
                                Gone are the days when at least one member of
                                the family used to be present in the house to
                                safeguard it. With change in social structure, a
                                security guard has been shouldered with more
                                responsibilities and thus the chowkidhar has
                                evolved into a Security Guard.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="counter-inner">
            <div className="counter-item-wrap row">
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">2500</span>+
                </div>
                <div className="Completed">Happy Clients</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">1000</span>+
                </div>
                <div className="Completed">House Keeping</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">100</span>%
                </div>
                <div className="Completed">Reviews</div>
              </div>
              <div className="col-lg-3 col-sm-6 counter-item">
                <div className="counter-no">
                  <span className="counter">250</span>+
                </div>
                <span className="Completed">Award Achieved</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="title-divider-center" />
              <h2 className="testimonial-section-title text-center">
                What our clients are saying about our guards:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="client-content left-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We are please to state that Suraskha Security & Allied
                  Services is providing us security service at our various
                  residential complex and we have not faced any major issue with
                  their service.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Abhinav Mishra</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="client-content right-content">
                <div className="pattern-overlay circle-patten" />
                <p className="client-review">
                  “We have deployed GlSuraskha Security & Allied Services
                  Private Limited for our ongoing project at Thane and they have
                  been able to meet out standard and expectation. We would like
                  to specifically mentioned efforts of your area manager in
                  providing uninterrupted service at our project.”
                </p>
                <div className="author-content">
                  <div className="author-info">
                    <h5 className="author-name">Dr. Arun Diaz</h5>
                  </div>
                  <figure className="author-img">
                    <img src="assets/img/user-img.png" alt />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BuildingSecurity